@import "./common/loadfonts.scss";

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

body {
  height: 100vh;
  overflow-y: hidden;
  background: white;
  font-family: 'Lato';
  font-weight: 300;
}

#root {
  height: 100%;
}

html {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  width: 100%;
}

.app__main-app-container {
  height: 100%;
  width: 100%;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
}