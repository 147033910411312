@import "../common/vars.scss";

.filter-list__container {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  display: flex;
  align-content: center;
  z-index: 100;
  padding-bottom: 3vh;
  padding-top: 3vh;
  justify-content: space-between;
  transition: all 0.3s;
  transform: translateY(0);

  @media only screen and (max-width: 800px) {
    background: white;
  }

  &.hide {
    transform: translateY(-10vh);
  }

  &.show {
    transform: translateY(0);
  }

  .title {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5vw;
    letter-spacing: 5px;
    font-size: 14pt;
  }

  .filters {
    display: flex;
    justify-content: flex-end;
    padding-right: 5vw;

    @media only screen and (max-width: 800px) {
      display: none;
    }

    .links {
      display: flex;
      align-content: center;
      margin-right: 3vw;

      .link {
        text-align: center;
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #101010;
        transition: transform 0.3s;
        transform: scale(1);
        cursor: pointer;
        position: relative;
        .underline {
          opacity: 0;
          height: 4px;
          width: 100%;
          border-bottom: 1px solid black;
          transition: all 0.4s;
        }

        &.selected {
          .underline {
            opacity: 1;
          }
        }

        &:hover {
          .underline {
            opacity: 1;
          }
        }
      }
    }

    .filter {
      text-align: center;
      padding-left: 0.8vw;
      padding-top: 12px;
      padding-bottom: 10px;
      color: $font-gray;
      transition: transform 0.3s;
      transform: scale(1);
      cursor: pointer;
      font-weight: 200;
      font-size: 9pt;
      max-width: 100px;
      transition: all 0.3s;

      &.show {
        max-width: 100px;
        overflow: hidden;
      }

      &.hide {
        max-width: 0px;
        overflow: hidden;
      }

      .underline {
        opacity: 0;
        height: 4px;
        width: 100%;
        border-bottom: 1px solid black;
        transition: all 0.4s;
      }

      &.selected {
        .underline {
          opacity: 1;
        }
      }

      &:hover {
        .underline {
          opacity: 1;
        }
      }
    }
  }
}