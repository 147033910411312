.header__container {
  .header__header {
    padding-left: 4vw;
    padding-right: 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5vh;
    padding-bottom: 3vh;
    cursor: pointer;

    .logo {
      width: 90px;
    }

    .title {
      display: flex;
      align-items: center;
      max-width: 400px;
    }

    span {
      padding-top: 33px;
      margin-left: 20px;
      font-size: 19px;
      font-weight: 200;
    }
  }
}