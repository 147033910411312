@import "../common/vars.scss";

.footer__container {
  padding-top: 6vh;
  padding-bottom: 6vh;
  background: white;
  display: flex;
  justify-content: space-between;

  .links {
    padding-left: 10vw;
      a {
        text-decoration: none;
      }
    .link {
      line-height: 18pt;
      color: #7e7e7e;
      transition: all 0.3s;
      transform: scale(1);
      cursor: pointer;


      &:hover {
        transform: scale(1.04);
        color: $dark-blue;
      }
    }
  }

  .copyright {
    color: #7e7e7e;
    padding-right: 10vw;
  }
}