// Colors
$white: #ffffff;
$black: #18191D;
$light-gray: #f2f2f2;
$gray: #E1DFDD;
$font-gray: #101010;
$dark-gray: #848587;
$yellow: #FD8B3F;
$orange: #FD8B3F;
$red: #E43131;
$green: #4CE431;
$dark-blue: #0A316F;
$blue: #4A76BD;
$light-blue: #CADAF8;
$gold: #FFBB34;
$cream: #FFBB34;
