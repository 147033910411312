.stl__container {
  .controls {
    position: absolute;
    height: 23px;
    top: 20px;
    right: 20px;
    z-index: 1000;

    .control {
      height: 23px !important;
      width: 23px !important;
      min-height: 23px !important;
      min-width: 23px !important;
      transition: all 0.3s;
      transform: scale(1);
      padding-left: 20px;

      &.expand {
        @media only screen and (max-width: 800px) {
          display: none;
        }
      }

      &:hover {
        transform: scale(1.08);
      }
    }
  }
}