@import "../common/vars.scss";

.menu__container {
  
  .menu__menu {
    position: absolute;
    top: 0;
    z-index: 2000;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    transition: all 0.4s;
    transform: scale(0);
    opacity: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .menu-items {
      font-size: 21pt;
      line-height: 43pt;
      text-align: center;
      padding-bottom: 10vh;

      .item {
        cursor: pointer;
        transition: all 0.3s;
        transform: scale(1);
        &:hover {
          color: $gold;
          transform: scale(1.03);
        }

        &.highlighted {
          color: $gold;
        }
      }
    }

    .hamburger {
      position: absolute;
      right: 30px;
      top: 30px;
    }

    &.show {
      transform: scale(1);
      opacity: 1;
    }

    &.hide {
      transform: scale(0);
      opacity: 0;
    }
  }
}