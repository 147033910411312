.block_dashboard__container {
  width: 100vw;
  height: 100vh;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .block_dashboard__about {
    width: 100vw;
    transform-origin: 50% 100%;
    transform: translateY(0px);
    transition: all 0.8s;
    opacity: 1;
    overflow: hidden;
    max-height: 1000px;
    display: flex;

    &.contact {
      flex-wrap: wrap;
      gap: 8vh;
    }

    .block-title {
      width: 100%;
    }

    .block_dashboard__about-content {
      display: flex;
      @media only screen and (max-width: 800px) {
        flex-wrap: wrap;
      }

      img {
        width: 40vw;
        margin-left: 5vw;
        object-fit: cover;

        &.small {
          width: 30vw;
          @media only screen and (max-width: 800px) {
            width: 90vw;
          }
        }

        @media only screen and (max-width: 800px) {
          width: 90vw;
          margin-left: 5vw;
          object-fit: contain;
          height: 90vw;
        }
      }

      .block_dashboard__description {
        width: 35vw;
        margin-left: 6vw;

        @media only screen and (max-width: 800px) {
          width: 88vw;
          margin-top: 6vh;
        }

        .title {
          font-size: 18pt;
          font-weight: 200;
          color: #222;
        }

        .description {
          font-weight: 200;
          color: #222;
          margin-top: 1vh;
          line-height: 20pt;
          font-size: 11pt;
        }
      }
    }


    &.show {
      transform: translateY(0px);
      opacity: 1;
      max-height: 1200px;
    }

    &.hide {
      opacity: 0;
      transform: translateY(500px);
      max-height: 0px;
    }
  }

  .block_dashboard__video_player {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s;
    transform: scale(0);
    opacity: 0;

    .close {
      cursor: pointer;
      width: 20px;
      top: 40px;
      right: 40px;
      position: fixed;
      z-index: 1004;
      transition: all 0.3s;
      transform: scale(1);

      &:hover {
        transition: all 0.3s;
        transform: scale(1.08);
      }
    }

    .video_container {
      position: absolute;
      height: 70vh;
      width: 70vw;
      left: 15vw;
      top: 15vh;
      z-index: 1001;
      border: black solid 1px;
      padding-right: 1px;
      background: black;

      @media only screen and (max-width: 800px) {
        height: auto;
        width: 90vw;
        left: 5vw;
        top: 25vh;
      }
    }

    .backdrop {
      background: white;
      opacity: 0.94;
      width: 100%;
      height: 100%;
      z-index: 1000;
      position: fixed;
      left: 0;
      top: 0;
    }

    &.hidden {
      opacity: 0;
      transform: scale(0);
    }

    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }

  .block_dashboard__stl_renderer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s;
    transform: scale(0);
    opacity: 0;

    .close {
      cursor: pointer;
      width: 20px;
      top: 40px;
      right: 40px;
      position: fixed;
      z-index: 1004;
      transition: all 0.3s;
      transform: scale(1);

      &:hover {
        transition: all 0.3s;
        transform: scale(1.08);
      }
    }

    &.hidden {
      opacity: 0;
      transform: scale(0);
    }

    &.show {
      opacity: 1;
      transform: scale(1);
    }

    .video_container {
      position: absolute;
      height: 70vh;
      width: 70vw;
      left: 15vw;
      top: 15vh;
      z-index: 1001;
      border: black solid 1px;
      padding-right: 1px;
      background: white;

      @media only screen and (max-width: 800px) {
        height: auto;
        width: 90vw;
        left: 5vw;
        top: 25vh;
      }
    }

    .backdrop {
      background: white;
      opacity: 0.94;
      width: 100%;
      height: 100%;
      z-index: 1000;
      position: fixed;
      left: 0;
      top: 0;
    }

    .stl__container {
      width: 70vw;
      height: 70vh;
    }
  }

  .block_dashboard__content {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 20vh;

    .block_dashboard__grid-items {
      display: grid;
      padding-bottom: 10vh;
      grid-column-gap: 1vw;
      grid-row-gap: 1vw;
      transform: translateY(0px);
      transition: all 0.5s;
      opacity: 1;
      max-height: 10000px;
      height: fit-content;

      &.show {
        opacity: 1;
        max-height: 10000px;
      }

      &.hide {
        opacity: 0;
        transform: translateY(100px);
        max-height: 0px;
        height: 0px;
        overflow-y: hidden;
      }

      .descriptor-title {
        margin-top: -6vh;
        text-align: center;
        color: #bbb;
      }

      img {
        //filter: grayscale(1);
        //transition: all 0.3s;

        &:hover {
          //filter: grayscale(0);
        }
      }
    }


    .first-page {
      width: 100%;

      .page-block__item {

        &:hover {
          transform: translateY(0px);
        }
      }

      img {
        margin-right: 8vw;
        margin-left: 5vw;
        width: 90vw;
        margin-bottom: -3px;
        height: auto;
        max-height: 80vh;
        filter: grayscale(1);
        transition: all 0.3s;

        &:hover {
          filter: grayscale(0);
        }
      }

      .page-block__detail {
        padding-right: 3vw;
        padding-left: 5vw;
        padding-bottom: 5vw;
        padding-top: 0vh;

        .first-page-content {
          background: white;
          background: white;
          padding-bottom: 6vh;
          padding-top: 5vh;
          padding-left: 4vw;
          margin-right: 2vw;
        }
      }
    }
  }

  .hamburger {
    height: 25px;
    transform: scale(1);
    transition: all 0.3s;
    cursor: pointer;
    right: 30px;
    top: 30px;
    z-index: 1000;
    position: absolute;
    @media only screen and (min-width: 800px) {
      display: none;
    }

    &.hide {
      display:none;
    }
  }

  .block_dashboard__overlay {
    position: absolute;
    z-index: 1000;
    height: 100%;
    width: 100%;
    top: 0;

    .stl__container {
      width: 40vw;
      height: 40vh;
    }
  }

  .login-form {
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    transition: all 0.5s;
    transform: scale(0);

    &.show {
      transform: scale(1);
    }

    &.hide {
      transform: scale(0);
    }

    .backdrop {
      background: white;
      height: 100vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .theform {
        .inputlabel {
        }

        input {
          font-size: 10pt;
          height: 15pt;
          margin-top: 8px;
        }

        .submit-button, .cancel-button {
          background: #229ccb;
          color: white;
          border-radius: 3px;
          margin-top: 20px;
          padding: 12px;
          cursor: pointer;
          transition: all 0.3s;
          transform: scale(1);

          &:hover {
            transform: scale(1.08);
          }
        }

        .cancel-button {
          background: black;
        }
      }
    }
  }
}