@charset "UTF-8";

$asset-pipeline: true !default;

// Used for creating the source string for fonts using @font-face
// Reference: http://goo.gl/Ru1bKP

@function font-url-prefixer($asset-pipeline) {
  @if $asset-pipeline == true {
    @return font-url;
  } @else {
    @return url;
  }
}

@function font-source-declaration(
  $font-family,
  $file-path,
  $asset-pipeline,
  $file-formats,
  $font-url) {

  $src: ();

  $formats-map: (
    ttf:  "#{$file-path}.ttf" format("ttf")
  );

  @each $key, $values in $formats-map {
    @if contains($file-formats, $key) {
      $file-path: nth($values, 1);
      $font-format: nth($values, 2);

      @if $asset-pipeline == true {
        $src: append($src, font-url($file-path) $font-format, comma);
      } @else {
        $src: append($src, url($file-path) $font-format, comma);
      }
    }
  }

  @return $src;
}

@mixin font-face(
  $font-family,
  $file-path,
  $weight: normal,
  $style: normal,
  $asset-pipeline: $asset-pipeline,
  $file-formats: ttf) {

  $font-url-prefix: font-url-prefixer($asset-pipeline);

  @font-face {
    font-family: $font-family;
    font-style: $style;
    font-weight: $weight;

    src: font-source-declaration(
      $font-family,
      $file-path,
      $asset-pipeline,
      $file-formats,
      $font-url-prefix
    );
  }
}

$font-url-prefix: font-url-prefixer($asset-pipeline);

@font-face {
  font-family: 'Lato';
  font-style: thin;
  font-weight: 100;
  src: url('./fonts/Lato-Thin.ttf');
  src: local('Lato'), local('Lato'),
       url('./fonts/Lato-Thin.ttf') format('truetype')
}

@font-face {
  font-family: 'Lato';
  font-style: light;
  font-weight: 200;
  src: url('./fonts/Lato-Light.ttf');
  src: local('Lato'), local('Lato'),
       url('./fonts/Lato-Light.ttf') format('truetype')
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Lato-Regular.ttf');
  src: local('Lato'), local('Lato'),
       url('./fonts/Lato-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Lato';
  font-style: bold;
  font-weight: 400;
  src: url('./fonts/Lato-Bold.ttf');
  src: local('Lato'), local('Lato'),
       url('./fonts/Lato-Bold.ttf') format('truetype')
}

@font-face {
  font-family: 'Lato';
  font-weight: 600;
  src: url('./fonts/Lato-Black.ttf');
  src: local('Lato'), local('Lato'),
       url('./fonts/Lato-Black.ttf') format('truetype')
}