.page-detail__container {
  width: 100vw;

  .feature-img {
    width: 90vw;
    margin-left: 5vw;
    object-fit: contain;
    max-height: 90vh;
  }

  .main-content {
    display: flex;
    width: 100%;
    padding-left: 10vw;
    padding-right: 10vw;
    margin-top: 11vh;

    .content {

      .title {
        font-weight: 300;
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 40px;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
      }

      .text-content {
        width: 70%;
        font-size: 14.5pt;
        line-height: 25pt;
        font-weight: 200;  
      }
    }
  }

  .hamburger {
    height: 25px;
    transform: scale(1);
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.08);
    }
  }
}