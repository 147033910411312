.page-block__container {
  width: 100%;

  .page-block__item {
    width: 100%;
    transition: all 0.3s;
    transform: translateY(0px);
    height: 100%;
    display: flex;
    justify-content: space-between;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    .img-container {
      width: 50vw;
      height: 40vw;
      display: flex;
      position: relative;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -webkit-tap-highlight-color: transparent;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @media only screen and (max-width: 800px) {
        width: 100vw;
        height: 100vw;
        margin-bottom: 50%;
      }

      &.left {
        .stl__container {
          background: white;
          border: 1px solid black;
          width: 40vw;
          height: 40vw;
          position: absolute;
          left: 0;

          @media only screen and (max-width: 800px) {
            width: 100vw;
            height: 100vw;
          }
        }

        justify-content: flex-start;
        .page-separator {
          width: 1px;
          background: #d1d1d1;
          left: 100%;
          height: 106%;
          top: 0px;
          position: absolute;
          margin-top: -3%;
        }


        .descriptor {
          position: absolute;
          left: 60%;
          top: 40%;
          width: 50%;
          transition: all 0.3s;
          transform: scale(1);
          opacity: 1;
          cursor: auto;

          .close {
            height: 18px !important;
            width: 18px !important;
            position: absolute;
            top: 20px;
            right: 20px;
            object-fit: contain;
            min-height: 0;
            display: none;
          }

          &.hidden {
            transform: scale(0);
            left: 90%;
            opacity: 0;
          }

          &.expand {
            top: 10%;
            left: 30%;
            width: 50vw;

            @media only screen and (max-width: 800px) {
              width: 80vw;
              left: 10vw;
              top: 60%;
            }

            .descriptor-body {
              .additional {
                max-height: 1000px !important;
              }

              .more {
                max-height: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
              }
            }

            .close {
              display: block;
            }
          }

          .descriptor-body {
            background: white;
            padding: 50px;
            border: 1px solid black;

            .title {
              font-weight: 300;
            }
            .subtitle {
              font-size: 9pt;
              font-weight: 200;
              margin-top: 5px;
            }

            .additional {
              height: auto;
              max-height: 0px;
              overflow: hidden;
              transition: max-height 0.5s;
              font-weight: 200;
              font-size: 9pt;
              line-height: 12pt;
            }

            .more-link {
              &.all {
                font-weight: 200;
                text-decoration: underline;
                cursor: pointer;
              }

              margin-top: 5px;
              a {
                color: black;
                font-weight: 300;
              }
            }

            .more {
              max-height: 30px;
              overflow: hidden;
              transition: all 0.3s;
              margin-top: 10px;
              transform: scale(1);
              cursor: pointer;
              font-weight: 200;
              width: fit-content;
              border-left: 1px solid black;
              padding-left: 20px;
              padding-bottom: 4px;
              padding-top: 2px;
              font-size: 9pt;

              &:hover {
                transform: scale(1.08);
              }
            }
          }

          @media only screen and (max-width: 800px) {
            left: 10vw;
            width: 80vw;
            top: 80%;
          }
        }

        .hover-mask {
          border: 1px solid black;
          position: absolute;
          left: 0px;
          height: 40vw;
          width: 40vw;
          opacity: 0;
          display: flex;
          justify-content: center;
          align-content: center;
          cursor: pointer;

          @media only screen and (max-width: 800px) {
            height: 100vw;
            width: 100vw;
            display: none;
          }

          .background-mask {
            background: white;
            opacity: 0.9;
            position: absolute;
            height: 100%;
            width: 100%;
          }
          transition: all 0.3s;
        }

        .action-button {
          opacity: 0;
          transition: all 0.3s;
          position: absolute;
          left: 0;
          width: 40vw;
          height: 40vw;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s;
          transform: scale(0.5);

          @media only screen and (max-width: 800px) {
            display: none;
          }

          a {
            text-decoration: none;
          }

          .action {
            font-size: 18pt;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: black;
            font-weight: 200;
            transition: all 0.3s;
            transform: scale(1);
            text-decoration: none !important;
          }
        }

        &:hover {
          .descriptor {
            transform: scale(1.04);

            &.contract {
              @media only screen and (min-width: 800px) {
                left: 35vw;
              }
            }
          }

          .hover-mask {
            opacity: 1;
            cursor: pointer;
          }

          .action-button {
            opacity: 1;
            transform: scale(1);
          }
        }


        canvas {
          position: absolute;
          right: 0px;
        }
      }

      &.right {
        .stl__container {
          background: white;
          border: 1px solid black;
          width: 40vw;
          height: 40vw;
          position: absolute;
          right: 0;

          @media only screen and (max-width: 800px) {
            width: 100vw;
            height: 100vw;
          }
        }

        justify-content: flex-end;
        .page-separator {
          width: 1px;
          background: #d1d1d1;
          right: calc(100% - 1px);
          height: 106%;
          top: 0px;
          position: absolute;
          margin-top: -3%;
        }

        .descriptor {
          position: absolute;
          left: -5%;
          top: 40%;
          transition: all 0.3s;
          transform: scale(1);
          opacity: 1;
          width: 30vw;
          cursor: auto;

          .close {
            height: 18px !important;
            width: 18px !important;
            position: absolute;
            top: 20px;
            right: 20px;
            object-fit: contain;
            min-height: 0;
            display: none;
          }

          &.expand {
            top: 10%;
            left: -40%;
            width: 50vw;

            @media only screen and (max-width: 800px) {
              width: 80vw;
              left: 10vw;
              top: 60%;
            }

            .descriptor-body {
              .additional {
                max-height: 1000px !important;
              }

              .more {
                max-height: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
              }
            }

            .close {
              display: block;
            }
          }

          .descriptor-body {
            background: white;
            padding: 50px;
            border: 1px solid black;
            .title {
              font-weight: 300;
            }
            .subtitle {
              font-size: 9pt;
              font-weight: 200;
              margin-top: 5px;
            }

            .additional {
              height: auto;
              max-height: 0px;
              overflow: hidden;
              transition: max-height 0.5s;
              font-weight: 200;
              font-size: 9pt;
              line-height: 12pt;
            }

            .more-link {
              &.all {
                font-weight: 200;
                text-decoration: underline;
                cursor: pointer;
              }

              margin-top: 5px;
              a {
                color: black;
                font-weight: 300;
              }
            }

            .more {
              max-height: 30px;
              overflow: hidden;
              transition: all 0.3s;
              margin-top: 10px;
              transform: scale(1);
              cursor: pointer;
              font-weight: 200;
              width: fit-content;
              border-left: 1px solid black;
              padding-left: 20px;
              padding-bottom: 4px;
              padding-top: 2px;
              font-size: 9pt;

              &:hover {
                transform: scale(1.08);
              }
            }
          }

          &.hidden {
            transform: scale(0);
            left: -40%;
            opacity: 0;
          }

          @media only screen and (max-width: 800px) {
            left: 10vw;
            width: 80vw;
            top: 80%;
          }
        }

        .hover-mask {
          border: 1px solid black;
          position: absolute;
          right: 0px;
          height: 40vw;
          width: 40vw;
          opacity: 0;
          transition: all 0.3s;
          cursor: pointer;

          @media only screen and (max-width: 800px) {
            display: none;
          }

          .background-mask {
            background: white;
            opacity: 0.9;
            position: absolute;
            height: 100%;
            width: 100%;
          }
        }

        .action-button {
          opacity: 0;
          transition: all 0.3s;
          position: absolute;
          right: 0;
          width: 40vw;
          height: 40vw;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s;
          transform: scale(0.5);

          @media only screen and (max-width: 800px) {
            display: none;
          }

          @media only screen and (max-width: 800px) {
            height: 100vw;
            width: 100vw;
          }

          .action {
            font-size: 18pt;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: black;
            font-weight: 200;
            transition: all 0.3s;
            transform: scale(1);
            cursor: pointer;
            text-decoration: none !important;

            a {
              text-decoration: none;
              color: white !important;
            }
          }
        }

        &:hover {
          .descriptor {
            transform: scale(1.04);
            &.contract {
              @media only screen and (min-width: 800px) {
                left: -10vw;
              }
            }


            .descriptor-body {

              .additional {
                height: auto;
                max-height: 0px;
                overflow: hidden;
                transition: max-height 0.5s;
              }
            }
          }

          .hover-mask {
            opacity: 1;
            cursor: pointer;
          }

          .action-button {
            opacity: 1;
            transform: scale(1);
          }
        }

        canvas {
          position: absolute;
          right: 0px;
        }
      }

      img {
        width: 40vw;
        min-height: 30vh;
        object-fit: cover;
        cursor: pointer;

        @media only screen and (max-width: 800px) {
          width: 100vw;
          min-height: 100vw;
        }
      }
    }

    .page-block__detail {
      padding-top: 5vh;
      padding-left: 2vw;
      padding-bottom: 5vh;


      .title {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #a5a5a5;
      }

      .subtitle {
        margin-top: 10px;
        color: #a5a5a5;
        text-decoration: none;
        color: #2f2f2f;
        transition: color .2s linear;
        font-size: 19px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-y: hidden;

        @media only screen and (max-width: 1300px)  {
          font-size: 17px;
        }
      }
    }
  }


  &.vertical {
    grid-column: span 4;
    grid-row: span 6;

    img {
      height: 120vh;
    }
  }

  &.small {
    grid-column: span 2;
    grid-row: span 3;
    img {
      height: 49vh;
    }
  }

  &.block {
    grid-column: span 3;
    grid-row: span 3;
    img {
      height: 60vh;
      object-fit: cover;
    }
  }

  @media only screen and (max-width: 950px)  {
    &.vertical {
      grid-column: span 6;
      grid-row: span 3;

      img {
        height: 40vh;
      }
    }

    &.small {
      grid-column: span 6;
      grid-row: span 3;
      img {
        height: 40vh;
      }
    }

    &.block {
      grid-column: span 6;
      grid-row: span 3;
      img {
        height: 40vh;
        object-fit: cover;
      }
    }
  }

}